<template>
  <span class="relative z-0 inline-flex space-x-1.5">
    <button
      type="button"
      :class="[treePanelOpen ? 'bg-white shadow-sm' : 'hover:bg-white dark:hover:bg-gray-700 bg-transparent hover:shadow-sm']"
      class="text-gray-500 border border-gray-300 dark:border-gray-700 p-1.5 rounded-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-100"
      @click.prevent="toggleTree"
    >
      <fa-icon
        icon="folders"
        class="flex-shrink-0 fa-fw"
      />
    </button>
    <button
      type="button"
      :class="[infoPanelOpen ? 'bg-white shadow-sm' : 'hover:bg-white dark:hover:bg-gray-700 bg-transparent hover:shadow-sm']"
      class="text-gray-500 border border-gray-300 dark:border-gray-700 p-1.5 rounded-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-100"
      @click.prevent="toggleInfo"
    >
      <fa-icon
        icon="circle-info"
        class="flex-shrink-0 fa-fw"
        aria-hidden="true"
      />
    </button>
  </span>
</template>
<script>

export default {
  name: 'AssetsExplorerToolbarPanelToggles',

  props: {
    treePanelOpen: {
      required: true,
      type: Boolean
    },
    infoPanelOpen: {
      required: true,
      type: Boolean
    }
  },

  methods: {
    toggleInfo() {
      this.$emit('toggle-panel-state', 'info')
    },
    toggleTree() {
      this.$emit('toggle-panel-state', 'tree')
    }

  }
}
</script>
